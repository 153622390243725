import React, { useState, useEffect, useRef } from "react";
import "./Education.css";
import DateSection from "../Experience/DateSection/DateSection";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../../../axios.js";
import toastStyle from "../../../../configs/toastConfig.js";

export const Education = ({ userData, setMarketersdata }) => {
  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  const [educationArray, setEducationArray] = useState([]);
  const [isAddEducationOpen, setIsAddEducationOpen] = useState(false);
  const [editDeleteDropdown, setEditDeleteDropdown] = useState({});
  const [toEdit, setToEdit] = useState(null);

  const defaultEducationDetails = {
    collegeName: "",
    collegeWebsite: "",
    collegeDegree: "",
    fieldOfStudy: "",
    grade: "",
    aboutCollege: "",
    dates: {
      from: {
        month: "",
        year: "",
      },
      till: {
        month: "",
        year: "",
      },
    },
    currently_pursuing: false,
  };

  // Initialize state with proper copy from localStorage or default
  const [newEducationDetails, setNewEducationDetails] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("educationFormData"));
    return savedData ? {...defaultEducationDetails, ...savedData} : {...defaultEducationDetails};
  });

  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("educationFormData", JSON.stringify(newEducationDetails));
  }, [newEducationDetails]);

  const mainContainerRef = useRef();

  // Initialize education array from props
  useEffect(() => {
    setEducationArray([...userData?.education]);
  }, [userData?.education]);

  // Scroll to form when opened
  useEffect(() => {
    if (isAddEducationOpen) {
      mainContainerRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [isAddEducationOpen]);

  const handleAddNewDetails = (e) => {
    setNewEducationDetails({
      ...newEducationDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    try {
      const { data } = await axios.post(
        "/marketers/updateMarketerEducation",
        newEducationDetails,
        {
          headers: {
            Authorization: "Bearer " + Marketer.token,
          },
        }
      );

      toast.success("Education Added Successfully", toastStyle);
      closeForm();
      setEducationArray(data?.data);
      setMarketersdata({ ...userData, education: data?.data });
    } catch (error) {
      console.error("Submit error:", error);
      toast.error("Failed to save education", toastStyle);
    }
  };

  const handleSaveExp = (e) => {
    handleSubmit(e);
  };

  const handleSelectedDates = (value) => {
    setNewEducationDetails({ ...newEducationDetails, dates: value });
  };

  const handleDeleteEducation = async (id) => {
    try {
      const { data } = await axios.delete(`/marketers/deleteEducation/${id}`, {
        headers: {
          Authorization: "Bearer " + Marketer.token,
        },
      });

      toast.success("Education Deleted Successfully", toastStyle);
      setEducationArray(data?.data);
      setMarketersdata({ ...userData, education: data?.data });
    } catch (error) {
      console.error("Delete error:", error);
      toast.error("Failed to delete education", toastStyle);
    }
  };

  const handleEditEducation = async (id) => {
    try {
      const { data } = await axios.post(
        `/marketers/editEducation/${id}`,
        newEducationDetails,
        {
          headers: {
            Authorization: "Bearer " + Marketer.token,
          },
        }
      );
      
      setEducationArray(data?.data);
      setMarketersdata({ ...userData, education: data?.data });
      toast.success("Education Updated Successfully", toastStyle);
      closeForm();
    } catch (error) {
      console.error("Edit error:", error);
      toast.error("Failed to update education", toastStyle);
    }
  };

  const handleEducationDropdown = (id) => {
    setEditDeleteDropdown({
      ...editDeleteDropdown,
      [id]: !editDeleteDropdown[id],
    });
  };

  const openEditComponent = (id, education) => {
    setNewEducationDetails(education);
    setToEdit(id);
    setEditDeleteDropdown({ [id]: false });
    setIsAddEducationOpen(true);
  };

  const closeForm = () => {
    setIsAddEducationOpen(false);
    setNewEducationDetails(defaultEducationDetails);
    setToEdit(null);
    localStorage.removeItem("educationFormData");
  };

  const handleCurrentlyWorkingChange = (e) => {
    setNewEducationDetails({
      ...newEducationDetails,
      currently_pursuing: e.target.checked,
    });
  };

  return (
    <div className="education_main_container">
      {educationArray.length > 0 ? (
        educationArray.map((educationElement) => (
          <div className="eachCollegeExperience" key={educationElement?._id}>
            <div className="writtenExperience">
              <div className="education-item-header">
                <div className="education-item-title">
                  {educationElement?.collegeDegree}

                  <div className="editDeleteComponent">
                    <span
                      className="material-symbols-outlined"
                      onClick={() => handleEducationDropdown(educationElement?._id)}
                    >
                      more_vert
                    </span>

                    {editDeleteDropdown[educationElement?._id] && (
                      <div className="editDeleteButtons">
                        <button
                          onClick={() =>
                            openEditComponent(
                              educationElement?._id,
                              educationElement
                            )
                          }
                        >
                          Edit
                        </button>
                        <button
                          onClick={() =>
                            handleDeleteEducation(educationElement?._id)
                          }
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="education-item-company">
                  {educationElement?.collegeName} |{" "}
                  {educationElement?.fieldOfStudy}
                </div>
                <div className="education-item-duration">
                  {educationElement?.dates?.from?.month}{" "}
                  {educationElement?.dates?.from?.year} -{" "}
                  {educationElement?.currently_pursuing
                    ? "Present"
                    : educationElement?.dates?.till?.month +
                      " " +
                      educationElement?.dates?.till?.year}
                </div>
              </div>
              <div className="education-item-description">
                {educationElement?.aboutCollege}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="eachCollegeExperience">
          Add your education to add credibility to your profile
        </div>
      )}

      {!isAddEducationOpen && (
        <div className="theAddButtonLine">
          <TwoButtonWrapper
            firstComponent={"Add new education"}
            height={"45px"}
            borderRadius={"10px"}
            componentFunction={() => setIsAddEducationOpen(true)}
          />
        </div>
      )}

      <form className="education_form formScrollBarDisplay">
        <div
          className="innerExperienceSection"
          style={
            !isAddEducationOpen
              ? { paddingBottom: "130px" }
              : { paddingBottom: "30px" }
          }
        ></div>

        {isAddEducationOpen && (
          <div ref={mainContainerRef} className="mainContainer">
            <div className="educationDetails">
              <p className="label">
                College Name<sup>*</sup>
              </p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name="collegeName"
                  value={newEducationDetails.collegeName}
                  onChange={handleAddNewDetails}
                  placeholder="Add college name"
                />
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <p className="label">College Website</p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name="collegeWebsite"
                  value={newEducationDetails.collegeWebsite}
                  onChange={handleAddNewDetails}
                  placeholder="Add website url"
                />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <p className="label">Degree</p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name="collegeDegree"
                  value={newEducationDetails.collegeDegree}
                  onChange={handleAddNewDetails}
                  placeholder="Add your degree"
                />
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <p className="label">Field of Study*</p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name="fieldOfStudy"
                  value={newEducationDetails.fieldOfStudy}
                  onChange={handleAddNewDetails}
                  placeholder="Add your field of study"
                />
              </div>
            </div>

            <div className="educationFormDates">
              <DateSection
                selectedDate={newEducationDetails.dates}
                setSelectedDate={handleSelectedDates}
                dateType={"from"}
                currentlyWorking={newEducationDetails.currently_pursuing}
              />

              {!newEducationDetails.currently_pursuing && (
                <DateSection
                  selectedDate={newEducationDetails.dates}
                  setSelectedDate={handleSelectedDates}
                  dateType={"till"}
                  currentlyWorking={newEducationDetails.currently_pursuing}
                />
              )}
            </div>
            <div className="checkBoxWrapper">
              <input
                type="checkbox"
                checked={newEducationDetails.currently_pursuing}
                onChange={handleCurrentlyWorkingChange}
              />
              <span>Currently pursuing this education</span>
            </div>

            <div style={{ width: "100%" }}>
              <p className="label">Grade*</p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name="grade"
                  value={newEducationDetails.grade}
                  onChange={handleAddNewDetails}
                  placeholder="Add your grades"
                />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <p className="label">About your time at college</p>
              <div className="inputWrapper textareaWrapper">
                <textarea
                  name="aboutCollege"
                  rows="6"
                  value={newEducationDetails.aboutCollege}
                  onChange={handleAddNewDetails}
                />
              </div>
            </div>

            <div className="newFormButtons">
              <TwoButtonWrapper
                firstComponent={"Cancel"}
                backgroundColor={"white"}
                color={"black"}
                padding={"5px 20px"}
                height={"45px"}
                borderRadius={"10px"}
                componentFunction={closeForm}
              />
              {toEdit ? (
                <TwoButtonWrapper
                  firstComponent={"Update"}
                  padding={"5px 20px"}
                  height={"45px"}
                  borderRadius={"10px"}
                  componentFunction={() => handleEditEducation(toEdit)}
                />
              ) : (
                <TwoButtonWrapper
                  firstComponent={"Save"}
                  padding={"5px 20px"}
                  height={"45px"}
                  borderRadius={"10px"}
                  componentFunction={handleSaveExp}
                />
              )}
            </div>
          </div>
        )}
      </form>
      <ToastContainer />
    </div>
  );
};
import React, { useState } from "react";
import "../../styles/Login.css";
import "../../styles/AgencyRegistration.css";

import axios from "../../axios.js";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import toastStyle from "../../configs/toastConfig.js";

function ConfirmPassword() {
  const location = useLocation();
  const history = useHistory();
  const [message, setmessage] = useState("");
  const [alert, setalert] = useState("");
  const [submitbtn, setSubmitbtn] = useState(false);

  const [user, setUser] = useState({
    email: "",
    otp: "",
    password: "",
    confirmpassword: "",
  });

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setUser((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (user.password === user.confirmpassword && user.password != "") {
      try {
        const { data } = await axios.post("/changePassword", {
          email: location.state.email,
          otpCode: location.state.otp,
          password: user.password,
        });
        if (data?.success) {
          toast.success("Password Changed Successfully", toastStyle);
          history.push("/user-login");
        }
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.message, toastStyle);
        }
      }
    } else {
      toast.error("Password and Confirm Password are not Same", toastStyle);
    }

    // loading icon starts
    setSubmitbtn(false);
    // loading icon ends
  };

  return (
    <div className="login__cont_new">
      <div className="login">
        <div className="sec__left_new">
          <div className="upper">
            <h1 className="heading">Almost There!!</h1>
          </div>
          <div className="content">
            <div className="lower">
              <span> Welcome back :)</span>
              <p>See you on the other side 👋</p>
            </div>
            {/* <div className="lower">

                            💸<span>On-demand creative workforce</span>
                        </div>
                        <div className="lower">

                            👀 <span>On-demand creative workforce</span>
                        </div> */}
          </div>
        </div>
        <div className="sec__right_new">
          <h1
            className="fw__600 logreg__title color__orange"
            style={{ textAlign: "left" }}
          >
            Reset Password
          </h1>
          <form className="login__form">
            <div className="password_label">
              <label>Password * </label>
              {/* <label className="min__char">Minimum 8 characters</label> */}
            </div>
            <input
              onChange={changeHandler}
              type="password"
              name="password"
              placeholder="Enter New Password"
              required
              className="epass"
            />

            <label>Confirm Password *</label>

            <input
              onChange={changeHandler}
              type="password"
              name="confirmpassword"
              placeholder="Re-enter Your Password"
              required
              className="epass"
            />

            <div className="log__for">
              {/* <label>
                                *You will be contacted from our team within 24 hrs for profile
                                activation.
                            </label> */}
            </div>
            <p className="color__orange">{alert}</p>
            <button
              type="submit"
              className="login__btn"
              onClick={SubmitHandler}
            >
              {/* <Link to='/login' className="color__white"> */}
              Submit
              {/* {submitbtn && (
                                    <i className="fas fa-circle-notch donebtnclient"></i>
                                )}
                                {submitbtn && " Registering"}
                                {!submitbtn && "Register"} */}
              {/* </Link> */}
            </button>
            <p className="color__orange">{message}</p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ConfirmPassword;

import React, { useState } from "react";
import "../../styles/Login.css";
import "../../styles/AgencyRegistration.css";
import { toast } from "react-toastify";
import toastStyle from "../../configs/toastConfig.js";
import axios from "../../axios.js";
import { useHistory } from "react-router-dom";
import { Oval } from "react-loader-spinner";

function ForgotPassword() {
  const history = useHistory();
  const [submitbtn, setSubmitbtn] = useState(false);
  const [otpButtonLoading, setOtpButtonLoading] = useState(false);

  const SubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("/getOtpbyMail", {
        email: user.email,
        otp: user.otp,
      });
      if (data.success) {
        history.push({
          pathname: "/confirmpassword",
          state: { email: user.email, otp: user.otp },
        });
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, toastStyle);
      }
    }
  };
  const OtpEnterHandler = async () => {
    try {
      setOtpButtonLoading(true);
      const { data } = await axios.post("/emailSend", {
        email: user.email,
      });
      if (data?.success) {
        setSubmitbtn(true);
        toast.success("OTP sent successfully", toastStyle);
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, toastStyle);
      }
    } finally {
      setOtpButtonLoading(false);
    }
  };
  const [user, setUser] = useState({
    email: "",
    otp: "",
    password: "",
  });

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setUser((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <div className="login__cont_new">
      <div className="login">
        <div className="sec__left_new">
          <div className="upper">
            <h1 className="heading">Happens to the best of us!!</h1>
          </div>
          <div className="content">
            <div className="lower">
              <span>We’ve got you covered</span>
            </div>
          </div>
        </div>

        <div className="sec__right_new">
          <h1 className="fw__600 logreg__title color__orange">
            Forgot Password
          </h1>
          <div
            className="login__form"
            //  onSubmit={SubmitHandler}
          >
            <label>Your E-mail</label>
            <input
              onChange={changeHandler}
              name="email"
              placeholder="Enter your registered E-mail"
              className="epass"
              type="text"
              required
            />

            <div className="log__for">
              <label>OTP will be sent to your mail ID</label>
            </div>
            <button
              className="login__btn"
              onClick={() => OtpEnterHandler()}
              disabled={otpButtonLoading}
            >
              {otpButtonLoading ? (
                <Oval
                  height="30"
                  width="30"
                  radius="18"
                  color="white"
                  secondaryColor="black"
                  ariaLabel="loading"
                />
              ) : (
                "Send OTP"
              )}
            </button>
          </div>
          {submitbtn && (
            <form className="login__form" id="forgotpassword">
              <input
                onChange={changeHandler}
                name="otp"
                placeholder="Enter OTP"
                className="epass"
                required
                style={{ marginTop: "30px" }}
                type="text"
              />
              <button
                type="submit"
                className="login__btn"
                onClick={SubmitHandler}
              >
                {/* <Link to="/confirmpassword" className="color__white" style={{ textDecoration: 'none' }}> */}
                Submit OTP
                {/* </Link> */}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;

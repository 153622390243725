import React, { useEffect, useState } from "react";
import deleteIcon from "./icons/delete.svg";
import linkedin from "./icons/linkedin.png";
import twitter from "./icons/twitter.png";
import instagram from "./icons/instagram.png";
import facebook from "./icons/facebook.png";
import youtube from "./icons/youtube.png";
import tiktok from "./icons/tiktok.png";
import whatsapp from "./icons/whatsapp.png";
import telegram from "./icons/telegram.png";
import "./Links.css";
import axios from "../../../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import toastStyle from "../../../../configs/toastConfig";

const Links = ({ socialLinks, setMarketersdata }) => {
  const [isAddNewLinkOpen, setIsAddNewLinkOpen] = useState(false);
  const [linkTitle, setLinkTitle] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const socialMediaLinks = [
    {
      id: 1,
      name: "linkedIn",
      icon: linkedin,
      placeholderName: "LinkedIn",
    },
    {
      id: 2,
      name: "xProfile",
      icon: twitter,
      placeholderName: "X profile",
    },
    {
      id: 3,
      name: "instagram",
      icon: instagram,
      placeholderName: "Instagram",
    },
  ];

  // Initialize state with proper object/array copies
  const [linkObj, setLinkObj] = useState({...socialLinks});
  const [errors, setErrors] = useState([]);
  const [otherLinks, setOtherLinks] = useState([...socialLinks.otherLinks]);
  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  const [saveText, setSaveText] = useState("Save");

  // Initialize from local storage or props
  useEffect(() => {
    const savedLinks = JSON.parse(localStorage.getItem("savedLinks"));
    if (savedLinks) {
      // Merge with current props to ensure fresh data
      setLinkObj({...socialLinks, ...savedLinks.linkObj});
      setOtherLinks([...socialLinks.otherLinks, ...savedLinks.otherLinks]);
    } else {
      setLinkObj({...socialLinks});
      setOtherLinks([...socialLinks.otherLinks]);
    }
  }, [socialLinks]);

  // Update local storage when data changes
  useEffect(() => {
    const dataToSave = {
      linkObj: {...linkObj},
      otherLinks: [...otherLinks]
    };
    localStorage.setItem("savedLinks", JSON.stringify(dataToSave));
  }, [linkObj, otherLinks]);

  const handleAddNewLink = () => {
    if (!linkTitle || !linkUrl) {
      toast.error("Please fill both title and URL", toastStyle);
      return;
    }

    const newLink = {
      linkTitle: linkTitle.trim(),
      linkUrl: linkUrl.trim(),
      _id: Date.now() // Temporary ID for local use
    };

    setOtherLinks(prev => [...prev, newLink]);
    setIsAddNewLinkOpen(false);
    setLinkTitle("");
    setLinkUrl("");
  };

  const regexPatterns = {
    linkedIn:
      /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/,
    xProfile: /^((https?:\/\/)?(www\.)?x\.com\/)([a-zA-Z0-9_.-]+\/?)$/,
    instagram: /^((https?:\/\/)?(www\.)?instagram\.com\/)([a-zA-Z0-9_.-]+\/?)$/,
  };

  // const onChangeValueofLink = (e) => {
  //   const { name, value } = e.target;
  //   const regex = regexPatterns[name];

  //   if (regex && regex.test(value)) {
  //     setLinkObj(prev => ({ ...prev, [name]: value }));
  //     if (errors.includes(name)) {
  //       setErrors(prev => prev.filter(error => error !== name));
  //     }
  //   } else if (value === "") {
  //     setLinkObj(prev => {
  //       const newObj = {...prev};
  //       delete newObj[name];
  //       return newObj;
  //     });
  //     if (errors.includes(name)) {
  //       setErrors(prev => prev.filter(error => error !== name));
  //     }
  //   } else {
  //     if (!errors.includes(name)) {
  //       setErrors(prev => [...prev, name]);
  //     }
  //   }
  // };
  const onChangeValueofLink = (e) => {
    const { name, value } = e.target;
    const regex = regexPatterns[name];
  
    // Always update the input value in state
    setLinkObj(prev => ({ ...prev, [name]: value }));
  
    // Then validate and update errors
    if (value === "") {
      if (errors.includes(name)) {
        setErrors(prev => prev.filter(error => error !== name));
      }
    } else if (regex && !regex.test(value)) {
      if (!errors.includes(name)) {
        setErrors(prev => [...prev, name]);
      }
    } else {
      if (errors.includes(name)) {
        setErrors(prev => prev.filter(error => error !== name));
      }
    }
  };

  useEffect(() => {
    if (saveText === "Saved") {
      const timer = setTimeout(() => setSaveText("Save"), 3000);
      return () => clearTimeout(timer);
    }
  }, [saveText]);

  const updateUserLinks = async (e) => {
    e.preventDefault();
    setSaveText("Saving...");

    try {
      const updatedLinks = {
        ...linkObj,
        otherLinks: otherLinks
      };

      const { data } = await axios.post(
        "/marketers/updateMarketer",
        { socialLinks: updatedLinks },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );

      if (data?.success) {
        // Clear local storage draft after successful save
        localStorage.removeItem("savedLinks");
        setLinkObj(data.data.socialLinks);
        setOtherLinks(data.data.socialLinks.otherLinks);
        setMarketersdata(data.data);
        setSaveText("Saved");
        toast.success("Links updated successfully!", toastStyle);
      }
    } catch (error) {
      setSaveText("Save");
      toast.error("Error updating links", toastStyle);
    }
  };

  const removeOptionLink = (id, name) => {
    setOtherLinks(prev => prev.filter(link => link._id !== id));
    toast.success(`${name} link removed successfully!`, toastStyle);
  };

  return (
    <main className="links_main_container">
      <ToastContainer />
      <form className="links_form formScrollBarDisplay">
        <div className="socialMediaProfile">
          <div className="linksContainer">
            {socialMediaLinks.map((link) => (
              <div key={link.id} className="linkDiv">
                <div className="linkIconAndImage">
                  <p className="linkIconName">{link.placeholderName}</p>
                </div>
                <input
                  value={linkObj[link.name] || ""}
                  name={link.name}
                  onChange={onChangeValueofLink}
                  placeholder={`Paste your ${link.placeholderName} profile link`}
                />
              </div>
            ))}

            {otherLinks.map((link) => (
              <div key={link._id || link.id} className="linkDiv">
                <div className="linkIconAndImage">
                  <p className="linkIconName">{link.linkTitle}</p>
                </div>
                <div className="linksInputContainer">
                  <input
                    value={link.linkUrl}
                    onChange={(e) => {
                      const updatedLinks = otherLinks.map(l => 
                        l._id === link._id ? {...l, linkUrl: e.target.value} : l
                      );
                      setOtherLinks(updatedLinks);
                    }}
                    placeholder="Paste your profile link"
                  />
                  <div>
                    <span
                      className="material-symbols-outlined"
                      onClick={() => removeOptionLink(link._id, link.linkTitle)}
                    >
                      delete
                    </span>
                  </div>
                </div>
              </div>
            ))}

            <div
              style={isAddNewLinkOpen ? { display: "none" } : { display: "flex" }}
              className="addNewLinkBtn"
              onClick={() => setIsAddNewLinkOpen(true)}
            >
              <span className="material-symbols-outlined">add_circle</span>
              Add Custom Link
            </div>
          </div>
        </div>
      </form>
      <div className="newLinkContainer">
        {isAddNewLinkOpen && (
          <div className="newLinkAddBox">
            <div className="linkDiv otherNewLink">
              <div className="newLinkTitle">
                <p>Link Title</p>
                <input
                  value={linkTitle}
                  onChange={(e) => setLinkTitle(e.target.value)}
                  placeholder="Give the link a title"
                />
              </div>
              <div className="newLinkUrl">
                <p>Link</p>
                <input
                  value={linkUrl}
                  onChange={(e) => setLinkUrl(e.target.value)}
                  placeholder="Enter the link here"
                />
              </div>
            </div>
            <div className="newFormButtons">
              <TwoButtonWrapper
                firstComponent={"Cancel"}
                backgroundColor={"white"}
                color={"black"}
                padding={"5px 20px"}
                height={"45px"}
                borderRadius={"10px"}
                componentFunction={() => setIsAddNewLinkOpen(false)}
              />
              <TwoButtonWrapper
                firstComponent={"Save"}
                padding={"5px 20px"}
                height={"45px"}
                borderRadius={"10px"}
                componentFunction={handleAddNewLink}
              />
            </div>
          </div>
        )}
      </div>

      <div className="linkFormDecidingButtons">
        <TwoButtonWrapper
          firstComponent={"Cancel"}
          backgroundColor={"white"}
          color={"black"}
          padding={"5px 20px"}
          height={"45px"}
          borderRadius={"10px"}
        />
        <TwoButtonWrapper
          firstComponent={saveText}
          padding={"5px 20px"}
          height={"45px"}
          borderRadius={"10px"}
          componentFunction={updateUserLinks}
        />
      </div>
    </main>
  );
};

export default Links;
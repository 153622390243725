import React, { useState, useEffect } from "react";
import ProfileCompo from "../../profileImage/ProfileImage";
import "./BasicInfo.css";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastStyle from "../../../../configs/toastConfig";
import axios from "../../../../axios";
import Loader from "../../../loader/Loader";
import { useTour } from "../../../../context/TourProviderContext";
const linkedInRegex =
  /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;

const BasicInfo = ({
  userData,
  setUserData,
  setUserProfileFormData,
  currentProfileData,
  onsubmitForm,
  setMarketersdata,
}) => {
  const [cropData, setCropData] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    linkedIn: "",
    email: "",
    phoneNumber: "",
    officialMailId: "",
  });
  const [loader, setLoader] = useState(true);
  const { startTour } = useTour();
  const resetToPrevData = (event) => {
    event.preventDefault();
    // Clear form data but preserve token
    localStorage.removeItem("userFormData");
    setUserData(currentProfileData);
  };

  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  // useEffect(() => {
  //   setUserData(currentProfileData);
  //   setLoader(false);
  // }, [currentProfileData]);
  useEffect(() => {
    // Clear only form data while preserving other data like token
    const savedFormData = localStorage.getItem("userFormData");
    if (savedFormData) {
      try {
        const parsedData = JSON.parse(savedFormData);
        // Merge with current profile data to ensure we have latest from server
        setUserData({...currentProfileData, ...parsedData});
      } catch (e) {
        console.error("Error parsing saved form data:", e);
        localStorage.removeItem("userFormData");
        setUserData(currentProfileData);
      }
    } else {
      setUserData(currentProfileData);
    }
    setLoader(false);
  }, [currentProfileData]);

  const syncYourCalendar = async () => {
    try {
      setLoader(true);
      // Clear form data but preserve token before sync
      localStorage.removeItem("userFormData");
      const { data } = await axios.get(
        `/google-calendar/setUp/${Marketer?.user?._id}`
      );
      if (data) {
        window.location.href = data;
      }
    } catch (error) {
      console.error("Calendar sync error:", error);
      toast.error("Failed to sync calendar", toastStyle);
      setLoader(false);
    }
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "linkedIn":
        if (!linkedInRegex.test(value)) {
          error = "Enter valid LinkedIn URL";
        }
        break;
      case "email":
        if (!value.includes("@") || value.length < 1) {
          error = "Enter a valid email address.";
        }
        break;
      case "phoneNumber":
        if (!/^\d{10}$/.test(value) || value.length < 1) {
          error = "Enter a valid 10-digit phone number.";
        }
        break;
      case "officialMailId":
        if (!value.includes("@") || value.length < 1) {
          error = "Enter your recovery emails!";
        }
        break;
      // Add more cases for other fields if needed
      default:
        break;
    }

    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const OnSubmit = (event) => {
    // startTour(2);
    event.preventDefault();
    setCropData("");
    let valid = true;
    let errorMessage = "Enter valid details";
    ["linkedIn", "email", "phoneNumber", "officialMailId"].forEach((key) => {
      if (fieldErrors[key] !== "") {
        valid = false;
        errorMessage = fieldErrors[key];
      }
    });

    if (!valid) {
      toast.error(errorMessage, toastStyle);
    } else {
      const updatedUserProfile = {
        ...userData,
      };

      onsubmitForm(updatedUserProfile);
    }
    localStorage.removeItem("userFormData");
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    validateField(name, value);
  
    setUserData(prev => {
      const updatedData = {...prev};
      
      if (name === "linkedIn") {
        updatedData.socialLinks = {
          ...updatedData.socialLinks,
          linkedIn: value
        };
      } else {
        updatedData[name] = value;
      }
  
      // Save to local storage without affecting other stored data
      const { token, ...dataToSave } = updatedData;
      localStorage.setItem("userFormData", JSON.stringify(dataToSave));
      
      return updatedData;
    });
  };

  const firstMendatoryInfo = [
    {
      Tittle: "LinkedIn Profile",
      placeHolder: "LinkedIn Profile URL",
      type: "text",
      name: "linkedIn",
      disable: false,
    },
    {
      Tittle: "Full Name",
      placeHolder: "First Name + Last Name",
      type: "text",
      name: "name",
      disable: false,
      required: true,
    },
    {
      Tittle: "Username",
      placeHolder: "Choose a unique username",
      type: "text",
      name: "username",
      disable: false,
      required: true,
    },
    {
      Tittle: "Title",
      placeHolder: "About you in few words",
      type: "string",
      name: "title",
      disable: false,
    },
  ];

  const secondMandatoryInfo = [
    {
      Tittle: "Sync Google Calendar",
      subtittle: "",
      placeHolder: `${
        userData?.verifiedContact?.googleMeetId
          ? "Calendar synced, click here to change"
          : "Click on 'Sync calendar' to authenticate"
      }`,
      type: "email",
      name: "SyncCalendar",
      disable: false,
    },
    {
      Tittle: "PRIMARY EMAIL",
      subtittle: "Preferably your personal email ID",
      placeHolder: "Your primary email",
      type: "email",
      name: "email",
      disable: false,
      required: true,
    },
    {
      Tittle: "Recovery Email",
      subtittle: "In case you lose access to your primary mail ID",
      placeHolder: "Your secondary email",
      type: "email",
      name: "officialMailId",
      disable: false,
      required: true,
    },
    {
      Tittle: "Whatsapp Number",
      placeHolder: "Whatspp Number for meeting notifications",
      type: "string",
      name: "phoneNumber",
      disable: false,
    },
  ];

  const handleLinkedInSync = async () => {
    try {
      setLoader(true);
      const url = userData?.socialLinks?.linkedIn;
      const { data } = await axios.post(
        `/marketers/connectViaLinkedIn`,
        { url },
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );    
      if (data?.success) {
        // Update both state and local storage with fresh data
        setMarketersdata(data.data);
        setUserData(data.data);
        const { token, ...dataToSave } = data.data;
        localStorage.setItem("userFormData", JSON.stringify(dataToSave));
        toast.success(data.message, toastStyle);
      } else {
        toast.error(data?.message || "LinkedIn sync failed", toastStyle);
      }
    } catch (err) {
      console.error("LinkedIn sync error:", err);
      toast.error("Error connecting to LinkedIn", toastStyle);
    } finally {
      setLoader(false);
    }
  };

  return (
    <main className="basicinfo_container">
      {loader ? (
        <Loader />
      ) : (
        <form className="BasicInfo formScrollBarDisplay">
          <div className="Fields">
            <div className="mandatoryField">
              {firstMendatoryInfo?.map((key, index) => (
                <div key={index} className="InfoDiv">
                  <div className="info-title">
                    <p>{key?.Tittle}</p>
                    {key?.required && <p>*</p>}
                  </div>
                  <span>{key?.subtittle}</span>
                  <div className="inputContainer">
                    <input
                      disabled={key.disable}
                      placeholder={key.placeHolder}
                      type={key.type}
                      name={key.name}
                      onChange={handleChange}
                      required={key.required}
                      value={
                        key.name === "linkedIn"
                          ? userData?.["socialLinks"]?.["linkedIn"]
                          : userData[key.name]
                      }
                    />
                    {key.name === "linkedIn" && (
                      <div
                        className={`iconBoxWithText ${
                          fieldErrors["linkedIn"] ? "Inactive" : ""
                        }`}
                        onClick={() => handleLinkedInSync()}
                      >
                        <span className="material-symbols-outlined">sync</span>
                        <p>Sync linkedIn</p>
                      </div>
                    )}
                  </div>

                  {fieldErrors[key.name] && (
                    <span className="errorColor">{fieldErrors[key.name]}</span>
                  )}
                </div>
              ))}
              <div className="InfoDiv">
                <p>About You</p>
                <textarea
                  rows="3"
                  cols="50"
                  name="about"
                  placeholder="A 100 words description about yourself"
                  onChange={handleChange}
                  value={userData.about}
                ></textarea>
              </div>
              {secondMandatoryInfo?.map((key, index) => (
                <div key={index} className="InfoDiv">
                  <div className="info-title">
                    <p>{key?.Tittle}</p>
                    {key?.required && <p>*</p>}
                  </div>
                  <span>{key.subtittle}</span>
                  <div className="inputContainer">
                    <input
                      disabled={key.disable}
                      placeholder={key.placeHolder}
                      type={key.type}
                      name={key.name}
                      onChange={handleChange}
                      value={userData[key.name]}
                      readOnly={key.name === "SyncCalendar" ? true : false}
                    />
                    {/* {key.name === "officialMailId" &&
                      (userData?.verifiedContact?.officialMailId ? (
                        <div className="iconBoxWithText">
                          <span className="material-symbols-outlined">
                            exclamation
                          </span>
                          <p>Verified</p>
                        </div>
                      ) : (
                        <div className="iconBoxWithText">
                          <span className="material-symbols-outlined">
                            exclamation
                          </span>
                          <p>Verify Email</p>
                        </div>
                      ))} */}
                    {/* {key.name === "email" &&
                      (userData?.verifiedContact?.email ? (
                        <div className="iconBoxWithText">
                          <span className="material-symbols-outlined">
                            exclamation
                          </span>
                          <p>Verified</p>
                        </div>
                      ) : (
                        <div className="iconBoxWithText">
                          <span className="material-symbols-outlined">
                            exclamation
                          </span>
                          <p>Verify Email</p>
                        </div>
                      ))} */}

                    {/* {key.name === "phoneNumber" &&
                      (userData?.verifiedContact?.phoneNumber ? (
                        <div className="iconBoxWithText">
                          <span className="material-symbols-outlined">
                            exclamation
                          </span>
                          <p>Verify Number</p>
                        </div>
                      ) : (
                        <div className="iconBoxWithText">
                          <span className="material-symbols-outlined">
                            exclamation
                          </span>
                          <p>Verify Number</p>
                        </div>
                      ))} */}

                    {key.name === "SyncCalendar" &&
                      userData?.verifiedContact &&
                      (userData?.verifiedContact["googleMeetId"] ? (
                        <div
                          className={`iconBoxWithText`}
                          onClick={() => syncYourCalendar()}
                        >
                          <span className="material-symbols-outlined">
                            sync
                          </span>
                          <p>Switch email</p>
                        </div>
                      ) : (
                        <div
                          className={`iconBoxWithText`}
                          onClick={() => syncYourCalendar()}
                        >
                          <span className="material-symbols-outlined">
                            sync
                          </span>
                          <p>Sync Calender</p>
                        </div>
                      ))}
                  </div>

                  {fieldErrors[key.name] && (
                    <span className="errorColor">{fieldErrors[key.name]}</span>
                  )}
                </div>
              ))}
              <div className="InfoDiv">
                <p>Profile Picture</p>
                <ProfileCompo
                  name="profilePhoto"
                  profilePhoto={userData?.profilePhoto}
                  setUserProfileFormData={setUserProfileFormData}
                  cropData={cropData}
                  setCropData={setCropData}
                />
              </div>
            </div>
          </div>
          <div className="newFormButtons">
            <TwoButtonWrapper
              firstComponent={"Cancel"}
              backgroundColor={"white"}
              color={"black"}
              padding={"5px 20px"}
              height={"45px"}
              borderRadius={"10px"}
              componentFunction={(e) => resetToPrevData(e)}
            ></TwoButtonWrapper>
            <TwoButtonWrapper
              firstComponent={"Save"}
              padding={"5px 20px"}
              height={"45px"}
              borderRadius={"10px"}
              componentFunction={OnSubmit}
            ></TwoButtonWrapper>
          </div>
        </form>
      )}
    </main>
  );
};

export default BasicInfo;

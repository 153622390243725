import React, { useState, useEffect } from "react";
import { UserResume } from "../../UserResume/UserResume";
import "./AvailabilityResume.css";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import imageUrls from "../../../../configs/profileAvatarUrls";

const Availability = ({
  userData,
  setUserData,
  onsubmitForm,
  currentProfileData,
}) => {
  const [openToWork, setIsOpenToWork] = useState(false);
  const [workTime, setWorkTime] = useState("");
  const [canRelocate, setCanRelocate] = useState(false);
  const [workType, setWorkType] = useState("");
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [avatar, setAvatar] = useState("");

  // Initialize form state from currentProfileData
  useEffect(() => {
    if (currentProfileData?.workDetails) {
      setIsOpenToWork(currentProfileData.workDetails.openToWork || false);
      setWorkTime(currentProfileData.workDetails.workTime || "");
      setCanRelocate(currentProfileData.workDetails.canRelocate || false);
      setWorkType(currentProfileData.workDetails.workType || "");
      setLocation(currentProfileData.workDetails.location || "");
      setSalary(currentProfileData.workDetails.salary || "");
      setYearsOfExperience(currentProfileData.workDetails.yearsOfExperience || "");
      setAvatar(currentProfileData.avatar || "");
      // Clear any existing localStorage data when we get fresh currentProfileData
      localStorage.removeItem("availabilityFormData");
    }
  }, [currentProfileData]);

  // Load draft from localStorage if no currentProfileData
  useEffect(() => {
    if (!currentProfileData?.workDetails) {
      const savedData = JSON.parse(localStorage.getItem("availabilityFormData"));
      if (savedData) {
        setIsOpenToWork(savedData.openToWork || false);
        setWorkTime(savedData.workTime || "");
        setCanRelocate(savedData.canRelocate || false);
        setWorkType(savedData.workType || "");
        setLocation(savedData.location || "");
        setSalary(savedData.salary || "");
        setYearsOfExperience(savedData.yearsOfExperience || "");
        setAvatar(savedData.avatar || "");
      }
    }
  }, []);

  // Save draft to localStorage when there are changes
  useEffect(() => {
    if (hasChanges) {
      const formData = {
        openToWork,
        workTime,
        canRelocate,
        workType,
        location,
        salary,
        yearsOfExperience,
        avatar,
      };
      localStorage.setItem("availabilityFormData", JSON.stringify(formData));
    }
  }, [
    openToWork,
    workTime,
    canRelocate,
    workType,
    location,
    salary,
    yearsOfExperience,
    avatar,
    hasChanges
  ]);

  // Check for changes compared to currentProfileData
  useEffect(() => {
    if (!currentProfileData?.workDetails) return;
    const currentData = {
      openToWork,
      workTime,
      canRelocate,
      workType,
      location,
      salary,
      yearsOfExperience,
      avatar,
    };

    const originalData = {
      openToWork: currentProfileData.workDetails.openToWork || false,
      workTime: currentProfileData.workDetails.workTime || "",
      canRelocate: currentProfileData.workDetails.canRelocate || false,
      workType: currentProfileData.workDetails.workType || "",
      location: currentProfileData.workDetails.location || "",
      salary: currentProfileData.workDetails.salary || "",
      yearsOfExperience: currentProfileData.workDetails.yearsOfExperience || "",
      avatar: currentProfileData.avatar || "",
    };

    const isChanged = JSON.stringify(currentData) !== JSON.stringify(originalData);
    setHasChanges(isChanged);
  }, [
    openToWork,
    workTime,
    canRelocate,
    workType,
    location,
    salary,
    yearsOfExperience,
    avatar,
    currentProfileData,
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedUserProfile = {
      ...userData,
      avatar: avatar,
      workDetails: {
        openToWork,
        workTime,
        canRelocate,
        workType,
        location,
        salary,
        yearsOfExperience,
      },
    };

    onsubmitForm(updatedUserProfile);
    // Clear localStorage after successful submission
    localStorage.removeItem("availabilityFormData");
    setHasChanges(false);
  };

  const resetToPrevData = (event) => {
    event.preventDefault();
    if (currentProfileData?.workDetails) {
      setIsOpenToWork(currentProfileData.workDetails.openToWork || false);
      setWorkTime(currentProfileData.workDetails.workTime || "");
      setCanRelocate(currentProfileData.workDetails.canRelocate || false);
      setWorkType(currentProfileData.workDetails.workType || "");
      setLocation(currentProfileData.workDetails.location || "");
      setSalary(currentProfileData.workDetails.salary || "");
      setYearsOfExperience(currentProfileData.workDetails.yearsOfExperience || "");
      setAvatar(currentProfileData.avatar || "");
    }
    // Clear localStorage when resetting
    localStorage.removeItem("availabilityFormData");
    setHasChanges(false);
  };

  const toggleOpenToWork = () => {
    setIsOpenToWork(!openToWork);
    setHasChanges(true);
  };

  const handleWorkTimeChange = (e) => {
    setWorkTime(e.target.value);
    setHasChanges(true);
  };

  const toggleCanRelocate = () => {
    setCanRelocate(!canRelocate);
    setHasChanges(true);
  };

  const handleWorkTypeChange = (e) => {
    setWorkType(e.target.value);
    setHasChanges(true);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
    setHasChanges(true);
  };

  const handleSalaryChange = (e) => {
    setSalary(e.target.value);
    setHasChanges(true);
  };

  const handleYearsOfExperience = (e) => {
    setYearsOfExperience(e.target.value);
    setHasChanges(true);
  };

  const handleAvatarSelect = (image) => {
    setAvatar(image);
    setHasChanges(true);
  };

  return (
    <main className="availabilityResume_container">
      <form className="AvailabilityAndResumeContainer" onSubmit={handleSubmit}>
        <div className="Fields">
          <div className="additionalField">
            <div className="radioButtonBox">
              <p className="fieldHeading">Open To Work*</p>
              <div>
                <div className="toggleButton">
                  <input
                    type="radio"
                    id="openToWorkYes"
                    name="openToWorkGroup"
                    checked={openToWork}
                    onChange={toggleOpenToWork}
                  />
                  <label htmlFor="openToWorkYes" className={openToWork ? "selectedText" : ""}>
                    Yes, I'm an active job seeker
                  </label>
                </div>

                <div className="toggleButton">
                  <input
                    type="radio"
                    id="openToWorkNo"
                    name="openToWorkGroup"
                    checked={!openToWork}
                    onChange={toggleOpenToWork}
                  />
                  <label htmlFor="openToWorkNo" className={!openToWork ? "selectedText" : ""}>
                    No, I'm just exploring opportunities passively
                  </label>
                </div>
              </div>
            </div>

            <div className="inputFieldBox">
              <label htmlFor="location">Location:</label>
              <input
                type="text"
                id="location"
                value={location}
                placeholder="Your current location"
                onChange={handleLocationChange}
              />
            </div>

            <div className="radioButtonBox">
              <p className="fieldHeading">Work Time*</p>
              <div>
                <div className="toggleButton">
                  <input
                    type="radio"
                    id="workTimeFullTime"
                    name="workTimeGroup"
                    value="full-time"
                    checked={workTime === "full-time"}
                    onChange={handleWorkTimeChange}
                  />
                  <label htmlFor="workTimeFullTime" className={workTime === "full-time" ? "selectedText" : ""}>
                    Full Time
                  </label>
                </div>

                <div className="toggleButton">
                  <input
                    type="radio"
                    id="workTimePartTime"
                    name="workTimeGroup"
                    value="part-time"
                    checked={workTime === "part-time"}
                    onChange={handleWorkTimeChange}
                  />
                  <label htmlFor="workTimePartTime" className={workTime === "part-time" ? "selectedText" : ""}>
                    Part Time
                  </label>
                </div>

                <div className="toggleButton">
                  <input
                    type="radio"
                    id="workTimeContract"
                    name="workTimeGroup"
                    value="contract"
                    checked={workTime === "contract"}
                    onChange={handleWorkTimeChange}
                  />
                  <label htmlFor="workTimeContract" className={workTime === "contract" ? "selectedText" : ""}>
                    Contract
                  </label>
                </div>

                <div className="toggleButton">
                  <input
                    type="radio"
                    id="workTimeFreelance"
                    name="workTimeGroup"
                    value="freelance"
                    checked={workTime === "freelance"}
                    onChange={handleWorkTimeChange}
                  />
                  <label htmlFor="workTimeFreelance" className={workTime === "freelance" ? "selectedText" : ""}>
                    Freelance
                  </label>
                </div>
              </div>
            </div>

            <div className="radioButtonBox">
              <p className="fieldHeading">Can Relocate*</p>
              <div className="toggleButton">
                <input
                  type="radio"
                  id="canRelocateTrue"
                  name="canRelocatedGroup"
                  checked={canRelocate}
                  onChange={toggleCanRelocate}
                />
                <label htmlFor="canRelocateTrue" className={canRelocate ? "selectedText" : ""}>
                  Yes
                </label>
              </div>
              <div className="toggleButton">
                <input
                  type="radio"
                  id="canRelocateFalse"
                  name="canRelocatedGroup"
                  checked={!canRelocate}
                  onChange={toggleCanRelocate}
                />
                <label htmlFor="canRelocateFalse" className={!canRelocate ? "selectedText" : ""}>
                  No
                </label>
              </div>
            </div>

            <div className="radioButtonBox">
              <p className="fieldHeading">Work Type*</p>
              <div>
                <div className="toggleButton">
                  <input
                    type="radio"
                    id="remote"
                    name="workType"
                    value="remote"
                    checked={workType === "remote"}
                    onChange={handleWorkTypeChange}
                  />
                  <label htmlFor="remote" className={workType === "remote" ? "selectedText" : ""}>
                    Remote
                  </label>
                </div>
                <div className="toggleButton">
                  <input
                    type="radio"
                    id="on-site"
                    name="workType"
                    value="on-site"
                    checked={workType === "on-site"}
                    onChange={handleWorkTypeChange}
                  />
                  <label htmlFor="on-site" className={workType === "on-site" ? "selectedText" : ""}>
                    On-Site
                  </label>
                </div>
              </div>
            </div>

            <div className="inputFieldBox">
              <label htmlFor="salary">Salary:</label>
              <input
                type="text"
                id="salary"
                value={salary}
                placeholder="Expected salary"
                onChange={handleSalaryChange}
              />
            </div>

            <div className="inputFieldBox">
              <label htmlFor="yearsofexperience">Years of experience</label>
              <input
                type="text"
                id="yearsofexperience"
                value={yearsOfExperience}
                placeholder="Years of experience"
                onChange={handleYearsOfExperience}
              />
            </div>
          </div>

          <div className="additionalField extraField">
            <div className="InfoDiv">
              <p className="fieldHeading">Resume</p>
              <UserResume resume={userData?.resume} />
            </div>
          </div>

          <div className="additionalField extraField">
            <p className="fieldHeading">Choose your avatar</p>
          </div>
        </div>
      </form>

      <div className="profileAvatarList">
        {imageUrls.map((image, index) => (
          <div className="singleAvatarComponent" key={index}>
            <img
              src={image}
              alt={`avatar-${index}`}
              className={`singleAvatar ${avatar === image ? "selectedAvatar" : ""}`}
              onClick={() => handleAvatarSelect(image)}
            />
          </div>
        ))}
      </div>

      <div className="newFormButtonContainer">
        <div className="newFormButtons">
          <TwoButtonWrapper
            firstComponent={"Cancel"}
            backgroundColor={"white"}
            color={"black"}
            padding={"5px 20px"}
            height={"45px"}
            borderRadius={"10px"}
            componentFunction={resetToPrevData}
          />
          <TwoButtonWrapper
            firstComponent={"Save"}
            padding={"5px 20px"}
            height={"45px"}
            borderRadius={"10px"}
            componentFunction={handleSubmit}
            disabled={!hasChanges}
          />
        </div>
      </div>
    </main>
  );
};

export default Availability;
import React, { useEffect, useRef, useState } from "react";
import magnify from "../../Talent/TalentPool/demoImages/magnify.svg";
import chevronDown from "../../../UserProfileCompo/UserPageContent.js/DemoImages/Individual Profile/chevron-down.svg";
import "./Experience.css";
import DateSection from "./DateSection/DateSection";
import plusIcon from "./image/plus-box.svg";
import cancelIcon from "./image/cancel-box.svg";
import axios from "../../../../axios";
import ProfileeImage from "../../profileImage/ProfileImage";
import { ToastContainer, toast } from "react-toastify";
import ChecklistOfExperienceType from "./ChecklistOfExperienceType/ChecklistOfExperienceType";
import { Redirect } from "../../../HomePageComponents/images";
import { RxCross2 } from "react-icons/rx";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import { CompanyLogo } from "./CompanyLogo/CompanyLogo";
import toastStyle from "../../../../configs/toastConfig";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

const Experience = ({ userData, caseStudies, setMarketersdata }) => {
  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  const [filteredCompanies, setFilterdCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [
    showCreateNewComponyProfileSection,
    setShowCreateNewComponyProfileSection,
  ] = useState(false);
  const [searchCompanyName, setSearchCompanyName] = useState("");
  const [experienceArray, setExperienceArray] = useState([]);
  const [cropData, setCropData] = useState("");
  const [isError, setIsError] = useState(true);
  const [isTypeSelected, setIsTypeSelected] = useState(() => {
    // Initialize from localStorage or default to false
    const savedState = localStorage.getItem("experienceFormOpen");
    return savedState ? JSON.parse(savedState) : false;
  });
  const [showSelectType, setShowSelectType] = useState(false);
  const [currentlyWorking, setCurrentlyWorking] = useState(false);
  const [addingClientComponent, setAddingClientComponent] = useState(false);
  const [addingProjectComponent, setAddingProjectComponent] = useState(false);
  const addingClientFocusRef = useRef(null);
  const adddingProjectFocusRef = useRef(null);
  const [editDeleteDropdown, setEditDeleteDropdown] = useState({});
  const [saveEdited, setSaveEdited] = useState(null);
  const [newTool, setNewTool] = useState("");
  const [newSkill, setNewSkill] = useState("");
  const [reloadState, setReloadState] = useState(false);
  const [jobTypeDropdown, setJobTypeDropdown] = useState(false);

  const defaultCompanyDetails = {
    companyName: "",
    isMarketingAgency: false,
    companyWebsite: "",
    companyLogo: "",
    role: "",
    type: "",
    dates: {
      from: {
        month: "",
        year: "",
      },
      till: {
        month: "",
        year: "",
      },
    },
    description: "",
    clients: [],
    projects: [],
    skills: [],
    tools: [],
    currently_working: false,
  };

  // Initialize state with proper copies from props and local storage
  const [newCompanyDetails, setNewCompanyDetails] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("newCompanyDetails"));
    return savedData
      ? { ...defaultCompanyDetails, ...savedData }
      : { ...defaultCompanyDetails };
  });

  const [newClientDetail, setNewClientDetail] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("newClientDetail"));
    return (
      savedData || {
        client_name: "",
        client_website: "",
        client_brand_logo: null,
      }
    );
  });

  const [newProjectDetail, setNewProjectDetail] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("newProjectDetail"));
    return (
      savedData || {
        customer_name: "",
        project_title: "",
        role: "",
        about: "",
      }
    );
  });

  const [editClientDetail, setEditClientDetail] = useState({
    _id: "",
    client_name: "",
    client_website: "",
    client_brand_logo: null,
  });

  const [editProjectDetail, setEditProjectDetail] = useState({
    _id: "",
    customer_name: "",
    project_title: "",
    role: "",
    about: "",
  });

  const mainContainerRef = useRef();

  // Initialize experience array from props
  useEffect(() => {
    setExperienceArray([...userData.experience]);
  }, [userData.experience]);

  // Save form open state to localStorage
  useEffect(() => {
    localStorage.setItem("experienceFormOpen", JSON.stringify(isTypeSelected));
  }, [isTypeSelected]);

  // Save to local storage when data changes
  useEffect(() => {
    localStorage.setItem(
      "newCompanyDetails",
      JSON.stringify(newCompanyDetails)
    );
  }, [newCompanyDetails]);

  useEffect(() => {
    localStorage.setItem("newClientDetail", JSON.stringify(newClientDetail));
  }, [newClientDetail]);

  useEffect(() => {
    localStorage.setItem("newProjectDetail", JSON.stringify(newProjectDetail));
  }, [newProjectDetail]);

  // Clear local storage on successful save
  const clearLocalDrafts = () => {
    localStorage.removeItem("newCompanyDetails");
    localStorage.removeItem("newClientDetail");
    localStorage.removeItem("newProjectDetail");
    localStorage.removeItem("experienceFormOpen");
  };

  // Scroll to form when opened
  useEffect(() => {
    if (isTypeSelected) {
      mainContainerRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [isTypeSelected]);

  const toggleForm = () => {
    setIsTypeSelected(!isTypeSelected);
  };

  const handleExperienceDropdown = (id) => {
    setEditDeleteDropdown({ [id]: !editDeleteDropdown[id] });
  };

  const handleDeleteExperience = async (exId) => {
    try {
      const { data } = await axios.delete(
        `/marketers/deleteExperience/${exId}`,
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );
      toast.success(data?.message, toastStyle);
      setExperienceArray(data?.data);
      setMarketersdata({ ...userData, experience: data?.data });
    } catch (error) {
      toast.error("Failed to delete experience", toastStyle);
      console.error("Delete error:", error);
    }
  };

  const handleEditExperience = async (exId) => {
    try {
      const { data } = await axios.post(
        `/marketers/editExperience/${exId}`,
        newCompanyDetails,
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );
      toast.success(data?.message, toastStyle);
      setExperienceArray(data?.data);
      setMarketersdata({ ...userData, experience: data?.data });
      setIsTypeSelected(false);
      clearLocalDrafts();
    } catch (error) {
      toast.error("Failed to update experience", toastStyle);
      console.error("Edit error:", error);
    }
  };

  const handleAddNewProject = async (id) => {
    const updatedProjects = [
      ...newCompanyDetails.projects,
      { ...newProjectDetail },
    ];
    setNewCompanyDetails({
      ...newCompanyDetails,
      projects: updatedProjects,
    });
    toast.success("Project Added Successfully", toastStyle);
    setNewProjectDetail({
      customer_name: "",
      project_title: "",
      role: "",
      about: "",
    });
    localStorage.removeItem("newProjectDetail");
  };

  const handleAddNewClient = async () => {
    const updatedClients = [
      ...newCompanyDetails.clients,
      { ...newClientDetail },
    ];
    setNewCompanyDetails({
      ...newCompanyDetails,
      clients: updatedClients,
    });
    toast.success("Client Added Successfully", toastStyle);
    setNewClientDetail({
      client_name: "",
      client_website: "",
      client_brand_logo: null,
    });
    localStorage.removeItem("newClientDetail");
  };

  const handleAddNewSkill = (e) => {
    if (e.key === "Enter" && newSkill.trim()) {
      const updatedSkills = [...newCompanyDetails.skills, newSkill.trim()];
      setNewCompanyDetails({
        ...newCompanyDetails,
        skills: updatedSkills,
      });
      setNewSkill("");
    }
  };

  const handleAddNewTool = (e) => {
    if (e.key === "Enter" && newTool.trim()) {
      const updatedTools = [...newCompanyDetails.tools, newTool.trim()];
      setNewCompanyDetails({
        ...newCompanyDetails,
        tools: updatedTools,
      });
      setNewTool("");
    }
  };

  const handleRemoveSkill = (name) => {
    const updatedSkills = newCompanyDetails.skills.filter(
      (skill) => skill !== name
    );
    setNewCompanyDetails({
      ...newCompanyDetails,
      skills: updatedSkills,
    });
  };

  const handleRemoveTool = (name) => {
    const updatedTools = newCompanyDetails.tools.filter(
      (tool) => tool !== name
    );
    setNewCompanyDetails({
      ...newCompanyDetails,
      tools: updatedTools,
    });
  };

  const handleEditClient = (id) => {
    const updatedClients = newCompanyDetails.clients.map((client) =>
      client._id === id ? { ...editClientDetail } : client
    );
    setNewCompanyDetails({
      ...newCompanyDetails,
      clients: updatedClients,
    });
    toast.success("Client Edited Successfully", toastStyle);
    setEditClientDetail({
      _id: "",
      client_name: "",
      client_website: "",
      client_brand_logo: null,
    });
  };

  const handleEditProject = (id) => {
    const updatedProjects = newCompanyDetails.projects.map((project) =>
      project._id === id ? { ...editProjectDetail } : project
    );
    setNewCompanyDetails({
      ...newCompanyDetails,
      projects: updatedProjects,
    });
    toast.success("Project Edited Successfully", toastStyle);
    setEditProjectDetail({
      _id: "",
      customer_name: "",
      project_title: "",
      role: "",
      about: "",
    });
  };

  const handleDeleteClient = (id) => {
    const updatedClients = newCompanyDetails.clients.filter(
      (client) => client._id !== id
    );
    setNewCompanyDetails({
      ...newCompanyDetails,
      clients: updatedClients,
    });
    toast.success("Client Deleted Successfully", toastStyle);
  };

  const handleDeleteProject = (id) => {
    const updatedProjects = newCompanyDetails.projects.filter(
      (project) => project._id !== id
    );
    setNewCompanyDetails({
      ...newCompanyDetails,
      projects: updatedProjects,
    });
    toast.success("Project Deleted Successfully", toastStyle);
  };

  // Focus management for form sections
  useEffect(() => {
    if (addingProjectComponent && adddingProjectFocusRef.current) {
      adddingProjectFocusRef.current.focus();
    }
  }, [addingProjectComponent]);

  useEffect(() => {
    if (addingClientComponent && addingClientFocusRef.current) {
      addingClientFocusRef.current.focus();
    }
  }, [addingClientComponent]);

  async function handleSearch(e) {
    try {
      setSelectedCompany({});
      setSearchCompanyName(e.target.value);
      setShowCreateNewComponyProfileSection(false);

      if (e.target.value.length >= 1) {
        const { data } = await axios.post(
          "/experience-company/get-all-companies/",
          {
            nameStartWith: e.target.value.toLowerCase(),
          }
        );
        if (data?.data) {
          setFilterdCompanies(data?.data);
        }
      }
    } catch (error) {
      console.error("Search error:", error);
    }
  }

  function handleAddNewDatails(e) {
    setNewCompanyDetails({
      ...newCompanyDetails,
      [e.target.name]: e.target.value,
    });
  }

  function handleShowCreateNewCompanyProfile() {
    setNewCompanyDetails({
      ...newCompanyDetails,
      companyName: searchCompanyName,
    });
    setSearchCompanyName("");
    setShowCreateNewComponyProfileSection(true);
  }

  function handleSelectCompany(filteredCompany) {
    const company = {
      companyName: filteredCompany?.name,
      companyWebsite: filteredCompany?.website,
      companyLogo: filteredCompany?.logo,
    };
    setSelectedCompany(company);
    setNewCompanyDetails({
      ...newCompanyDetails,
      ...company,
    });
    setSearchCompanyName("");
    setShowCreateNewComponyProfileSection(false);
  }

  function handleSelectedDates(value) {
    setNewCompanyDetails({ ...newCompanyDetails, dates: value });
  }

  function setNewCompanyLogo(url) {
    setNewCompanyDetails((prev) => ({ ...prev, companyLogo: url }));
  }

  function setNewCustomerBrandLogo(url) {
    setNewClientDetail((prev) => ({
      ...prev,
      client_brand_logo: url,
    }));
  }

  function handleEditCustomerBrandLogo(url) {
    setEditClientDetail((prev) => ({
      ...prev,
      client_brand_logo: url,
    }));
  }

  async function handleSubmit(e) {
    e?.preventDefault();
    try {
      const { data } = await axios.post(
        "/marketers/updateMarketerExperience",
        newCompanyDetails,
        {
          headers: {
            Authorization: "Bearer " + Marketer.token,
          },
        }
      );

      setIsError(false);
      toast.success("Experience Added Successfully", toastStyle);
      setIsTypeSelected(false);

      setNewCompanyDetails(defaultCompanyDetails);
      setExperienceArray(data?.data);
      setMarketersdata({ ...userData, experience: data?.data });
      setShowCreateNewComponyProfileSection(false);
      setSearchCompanyName("");
      setSelectedCompany();
      clearLocalDrafts();
    } catch (error) {
      console.error("Submit error:", error);
      setIsError(true);
      toast.error("Please fill all required fields", toastStyle);
    }
  }

  const createNewCompany = async () => {
    try {
      const { data } = await axios.post(
        "/experience-company/create-new-company",
        {
          name: newCompanyDetails?.companyName,
          logo: newCompanyDetails?.companyLogo,
          website: newCompanyDetails?.companyWebsite,
        },
        {
          headers: {
            Authorization: "Bearer " + Marketer.token,
          },
        }
      );
      if (data?.success) {
        toast.success("Company Created Successfully", toastStyle);
      }
    } catch (error) {
      console.error("Company creation error:", error);
      toast.error("Failed to create company", toastStyle);
    }
  };

  function handleSaveExp(e) {
    setCropData("");
    handleSubmit(e);
  }

  function handleCurrentlyWorkingChange(e) {
    setNewCompanyDetails({
      ...newCompanyDetails,
      currently_working: e.target.checked,
    });
  }

  const openEditComponent = async (exId, experience) => {
    setNewCompanyDetails(experience);
    setIsTypeSelected(true);
    setSaveEdited(exId);
    setEditDeleteDropdown({ [exId]: false });
  };

  const selectJobType = (type) => {
    setNewCompanyDetails({
      ...newCompanyDetails,
      type: type,
    });
    setJobTypeDropdown(false);
  };

  return (
    <main className={`experience_main_container `}>
      {experienceArray.length > 0 ? (
        experienceArray.map((experience) => {
          return (
            <div className="eachCompanyExperience" key={experience?._id}>
              <div
                className="companyLogoContainer"
                style={{ display: "inline" }}
              >
                <img
                  src={experience?.companyLogo}
                  alt=""
                  style={{
                    width: "40px",
                    height: "40px",
                    display: "inline",
                    borderRadius: "50%",
                  }}
                />
              </div>

              <div className="writtenExperience">
                <div className="experience-item-header">
                  <div className="experience-item-title">
                    <div>{experience?.role}</div>

                    <div className="editDeleteComponent">
                      <span
                        class="material-symbols-outlined"
                        onClick={() => handleExperienceDropdown(experience._id)}
                      >
                        more_vert
                      </span>

                      {editDeleteDropdown[experience._id] && (
                        <div className="editDeleteButtons">
                          <button
                            onClick={() =>
                              openEditComponent(experience?._id, experience)
                            }
                          >
                            Edit
                          </button>
                          <button
                            onClick={() =>
                              handleDeleteExperience(experience?._id)
                            }
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="experience-item-company">
                    {experience?.companyName}
                  </div>
                  <div className="experience-item-duration">
                    {experience?.dates?.from?.month}{" "}
                    {experience?.dates?.from?.year} -{" "}
                    {experience?.currently_working
                      ? "Present"
                      : experience?.dates?.till?.month +
                        " " +
                        experience?.dates?.till?.year}
                  </div>
                </div>
                <div className="experience-item-description">
                  {experience?.description}
                </div>
                {experience?.skills?.length > 0 && (
                  <div>
                    <p className="notableThingsHeading">Skills</p>
                    <div className="experienceNotableThings">
                      {experience?.skills?.map((skill) => {
                        return (
                          <div className="experienceFeatures">{skill}</div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {experience?.tools?.length > 0 && (
                  <div>
                    <p className="notableThingsHeading">Tools</p>
                    <div className="experienceNotableThings">
                      {experience?.tools?.map((tool) => {
                        return <div className="experienceFeatures">{tool}</div>;
                      })}
                    </div>
                  </div>
                )}

                {experience?.clients?.length > 0 && (
                  <div>
                    <p className="notableThingsHeading">Clients</p>
                    <div className="experienceNotableThings">
                      {experience?.clients?.map((client) => {
                        return (
                          <div className="experienceFeatures">
                            {client?.client_name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {experience?.projects?.length > 0 && (
                  <div>
                    <p className="notableThingsHeading">Projects</p>
                    <div className="experienceProjectsList">
                      {experience?.projects?.map((project) => {
                        return (
                          <div className="experienceProjects">
                            <div className="experienceProjectsTitle">
                              {project?.customer_name}
                            </div>
                            {project?.project_title && (
                              <div className="experienceProjectsAboutBox">
                                <div className="experienceProjectDescription">
                                  {project?.about}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <div className="eachCompanyExperience">
          Add your education to add credibility to your profile
        </div>
      )}

      <div className="theAddButtonLine">
        <TwoButtonWrapper
          firstComponent={isTypeSelected ? "Cancel" : "Add new experience"}
          height={"45px"}
          borderRadius={"10px"}
          componentFunction={toggleForm}
        />
      </div>

      {isTypeSelected && (
        <form className="experience_form formScrollBarDisplay">
          <div
            className="innerExperienceSection"
            style={{ paddingBottom: "30px" }}
          ></div>

          <div ref={mainContainerRef} className="mainContainer">
            <div className="companyDetails">
              <p className="label">
                Company Name<sup>*</sup>
              </p>
              <div className="searchBarWrapper inputWrapper">
                <img src={magnify} alt="" />
                <input
                  type="text"
                  name=""
                  id=""
                  defaultValue={newCompanyDetails?.companyName}
                  onChange={handleSearch}
                  placeholder="Add company name"
                />
              </div>
              <div
                className="companySearchedResults"
                style={
                  searchCompanyName
                    ? { display: "flex", flexDirection: "column" }
                    : { display: "none" }
                }
              >
                {filteredCompanies.length !== 0 ? (
                  filteredCompanies.map((filteredCompany) => (
                    <>
                      <div
                        key={filteredCompany._id}
                        className="searchedCompany"
                        onClick={() => handleSelectCompany(filteredCompany)}
                      >
                        <div className="searchedCompanyNameAndLogo">
                          <img src={filteredCompany.logo} alt="" />
                          <p>{filteredCompany.name}</p>
                        </div>
                        <p className="searchedCompanyWebsite">
                          {filteredCompany.website}
                        </p>
                      </div>

                      <hr />
                    </>
                  ))
                ) : (
                  <div
                    style={{
                      zIndex: "10000",
                      display: "flex",
                      height: "max-content",
                      flexDirection: "column",
                      backgroundColor: "white",
                    }}
                  >
                    <div className="noCompanyExistWrapper cancelIcon">
                      <img src={cancelIcon} alt="cancelIcon" />
                      <p>No company found</p>
                    </div>
                    <hr />
                    <button
                      type="button"
                      className="noCompanyExistWrapper plusIcon"
                      onClick={() => handleShowCreateNewCompanyProfile()}
                    >
                      <img src={plusIcon} alt="plusIcon" />
                      <p>Create New Company Profile</p>
                    </button>
                  </div>
                )}
              </div>
            </div>

            {showCreateNewComponyProfileSection && (
              <div className="createNewExperienceCompanyWrapper">
                <div className="companyNameWrapper">
                  <p className="label">
                    Company Name<sup>*</sup>
                  </p>
                  <div className="searchBarWrapper inputWrapper">
                    <input
                      type="text"
                      name="companyName"
                      disabled={showCreateNewComponyProfileSection}
                      value={newCompanyDetails.companyName}
                      onChange={handleAddNewDatails}
                    />
                  </div>
                </div>

                <div className="companyWebsiteWrapper">
                  <p className="label">
                    Company Website<sup>*</sup>
                  </p>
                  <div className="searchBarWrapper inputWrapper">
                    <input
                      type="text"
                      name="companyWebsite"
                      value={newCompanyDetails.companyWebsite}
                      onChange={handleAddNewDatails}
                      id=""
                    />
                  </div>
                </div>

                <div className="companyLogoWrapper">
                  <p className="label">
                    Company Logo<sup>*</sup>
                  </p>

                  <CompanyLogo
                    setURLFunction={setNewCompanyLogo}
                    logo={newCompanyDetails?.companyLogo}
                  />
                </div>
                <div className="newFormButtons">
                  <div></div>
                  <TwoButtonWrapper
                    firstComponent={"Save"}
                    padding={"5px 20px"}
                    height={"45px"}
                    borderRadius={"10px"}
                    componentFunction={createNewCompany}
                  ></TwoButtonWrapper>
                </div>
              </div>
            )}

            <div
              style={{
                width: "100%",
                marginTop: showCreateNewComponyProfileSection ? "50px" : "0px",
              }}
            >
              <p className="label">Company Website</p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name=""
                  id=""
                  value={newCompanyDetails.companyWebsite}
                  onChange={(e) =>
                    setNewCompanyDetails({
                      ...newCompanyDetails,
                      companyWebsite: e.target.value,
                    })
                  }
                  placeholder="Select/Add website url"
                />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <p className="label">Job Type</p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Select/Add you job type"
                  value={newCompanyDetails.type}
                />
                <div onClick={() => setJobTypeDropdown(!jobTypeDropdown)}>
                  {!jobTypeDropdown ? (
                    <MdExpandMore></MdExpandMore>
                  ) : (
                    <MdExpandLess></MdExpandLess>
                  )}
                </div>

                {jobTypeDropdown && (
                  <div className="jobTypeDropdown">
                    <div onClick={() => selectJobType("Full-Time")}>
                      Full-Time
                    </div>
                    <div onClick={() => selectJobType("Part-Time")}>
                      Part-Time
                    </div>
                    <div onClick={() => selectJobType("Contract")}>
                      Contract
                    </div>
                    <div onClick={() => selectJobType("Freelance")}>
                      Freelance
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <p className="label">Role</p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name=""
                  id=""
                  value={newCompanyDetails.role}
                  onChange={(e) =>
                    setNewCompanyDetails({
                      ...newCompanyDetails,
                      role: e.target.value,
                    })
                  }
                  placeholder="Choose a Role"
                />
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <p className="label">Skills</p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name=""
                  id=""
                  value={newSkill}
                  onChange={(e) => setNewSkill(e.target.value)}
                  onKeyDown={(e) => handleAddNewSkill(e)}
                  placeholder="Add skill and Enter"
                />
              </div>
              <div className="skillAndTagContainer">
                {newCompanyDetails?.skills?.map((element) => {
                  return (
                    <div className="skillOrTagItem">
                      {element}
                      <RxCross2
                        onClick={() => handleRemoveSkill(element)}
                      ></RxCross2>
                    </div>
                  );
                })}
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <p className="label">Tools</p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name=""
                  id=""
                  value={newTool}
                  onChange={(e) => setNewTool(e.target.value)}
                  onKeyDown={(e) => handleAddNewTool(e)}
                  placeholder="Add tool and Enter"
                />
              </div>
              <div className="skillAndTagContainer">
                {newCompanyDetails?.tools?.map((element) => {
                  return (
                    <div className="skillOrTagItem">
                      {element}
                      <RxCross2
                        onClick={() => handleRemoveTool(element)}
                      ></RxCross2>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="experienceFormDates">
              <DateSection
                selectedDate={newCompanyDetails.dates}
                setSelectedDate={handleSelectedDates}
                dateType={"from"}
                currentlyWorking={newCompanyDetails?.currently_working}
              />

              {!newCompanyDetails?.currently_working && (
                <DateSection
                  selectedDate={newCompanyDetails.dates}
                  setSelectedDate={handleSelectedDates}
                  dateType={"till"}
                  currentlyWorking={newCompanyDetails?.currently_working}
                />
              )}
            </div>
            <div className="checkBoxWrapper">
              <input
                type="checkbox"
                checked={newCompanyDetails?.currently_working}
                onChange={handleCurrentlyWorkingChange}
              />
              <span>I am currently working in this role</span>
            </div>
            <div style={{ width: "100%" }}>
              <p className="label">
                Write something about your time at this company
              </p>
              <div className="inputWrapper textareaWrapper">
                <textarea
                  type="text"
                  name=""
                  id=""
                  rows="6"
                  value={newCompanyDetails.description}
                  onChange={(e) =>
                    setNewCompanyDetails({
                      ...newCompanyDetails,
                      description: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div
              className="AddMoreInfo"
              ref={addingClientFocusRef}
              onClick={() => setAddingClientComponent(!addingClientComponent)}
            >
              Add/Edit Client
            </div>
            {addingClientComponent && (
              <>
                {newCompanyDetails?.clients?.map((client) => {
                  return (
                    <div className="addingClientComponent">
                      <div style={{ width: "100%" }}>
                        <p className="label">Client/Customer</p>
                        <div className="inputWrapper">
                          <input
                            type="text"
                            name="client_name"
                            id=""
                            placeholder="Select/Add - client/customer"
                            defaultValue={client?.client_name}
                            readOnly={
                              editClientDetail?._id === client?._id
                                ? false
                                : true
                            }
                            value={
                              editClientDetail?._id === client?._id
                                ? editClientDetail?.client_name
                                : client?.client_name
                            }
                            onChange={(e) =>
                              setEditClientDetail({
                                ...editClientDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div style={{ width: "100%" }}>
                        <p className="label">Client/Customer Website</p>
                        <div className="inputWrapper">
                          <input
                            type="text"
                            name="client_website"
                            id=""
                            placeholder="Add Website URL"
                            defaultValue={client?.client_website}
                            readOnly={
                              editClientDetail?._id === client?._id
                                ? false
                                : true
                            }
                            value={
                              editClientDetail?._id === client?._id
                                ? editClientDetail?.client_website
                                : client?.client_website
                            }
                            onChange={(e) =>
                              setEditClientDetail({
                                ...editClientDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <p className="label">
                          Upload client/customer brand logo
                        </p>
                        <CompanyLogo
                          setURLFunction={handleEditCustomerBrandLogo}
                          logo={client?.client_brand_logo}
                          style={{
                            pointerEvents:
                              editClientDetail?._id === client?._id
                                ? "auto"
                                : "none",
                          }}
                        />
                      </div>
                      <div className="newFormButtons">
                        <TwoButtonWrapper
                          firstComponent={"Delete"}
                          backgroundColor={"white"}
                          color={"black"}
                          padding={"5px 20px"}
                          height={"45px"}
                          borderRadius={"10px"}
                          componentFunction={() =>
                            handleDeleteClient(client?._id)
                          }
                        ></TwoButtonWrapper>
                        {editClientDetail?._id === client?._id ? (
                          <TwoButtonWrapper
                            firstComponent={"Save"}
                            padding={"5px 20px"}
                            height={"45px"}
                            borderRadius={"10px"}
                            componentFunction={() =>
                              handleEditClient(client?._id)
                            }
                          ></TwoButtonWrapper>
                        ) : (
                          <TwoButtonWrapper
                            firstComponent={"Enable Editing"}
                            padding={"5px 20px"}
                            height={"45px"}
                            borderRadius={"10px"}
                            componentFunction={() =>
                              setEditClientDetail(client)
                            }
                          ></TwoButtonWrapper>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="addingClientComponent">
                  <div style={{ width: "100%" }}>
                    <p className="label">Client/Customer</p>
                    <div className="inputWrapper">
                      <input
                        type="text"
                        name="client_name"
                        id=""
                        placeholder="Select/Add - client/customer"
                        value={newClientDetail?.client_name}
                        onChange={(e) =>
                          setNewClientDetail({
                            ...newClientDetail,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <p className="label">Client/Customer Website</p>
                    <div className="inputWrapper">
                      <input
                        type="text"
                        name="client_website"
                        id=""
                        placeholder="Add Website URL"
                        value={newClientDetail?.client_website}
                        onChange={(e) =>
                          setNewClientDetail({
                            ...newClientDetail,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <p className="label">Upload client/customer brand logo</p>
                    <CompanyLogo
                      setURLFunction={setNewCustomerBrandLogo}
                      logo={newClientDetail?.client_brand_logo}
                    ></CompanyLogo>
                  </div>

                  <div className="newFormButtons">
                    <TwoButtonWrapper
                      firstComponent={"Cancel"}
                      backgroundColor={"white"}
                      color={"black"}
                      padding={"5px 20px"}
                      height={"45px"}
                      borderRadius={"10px"}
                      componentFunction={() => setAddingClientComponent(false)}
                    ></TwoButtonWrapper>
                    <TwoButtonWrapper
                      firstComponent={"Save"}
                      padding={"5px 20px"}
                      height={"45px"}
                      borderRadius={"10px"}
                      componentFunction={handleAddNewClient}
                    ></TwoButtonWrapper>
                  </div>
                </div>
              </>
            )}

            <div
              className="AddMoreInfo"
              ref={adddingProjectFocusRef}
              onClick={() => setAddingProjectComponent(!addingProjectComponent)}
            >
              Add/Edit Project
            </div>
            {addingProjectComponent && (
              <>
                {newCompanyDetails?.projects?.map((project) => {
                  return (
                    <div className="addingProjectComponent">
                      <div>
                        <div style={{ width: "100%" }}>
                          <p className="label">Client/Customer</p>
                          <div className="inputWrapper">
                            <input
                              type="text"
                              name="customer_name"
                              id=""
                              placeholder="Select/Add - client/customer"
                              defaultValue={project?.customer_name}
                              readOnly={
                                editProjectDetail?._id === project?._id
                                  ? false
                                  : true
                              }
                              value={
                                editProjectDetail?._id === project?._id
                                  ? editProjectDetail?.customer_name
                                  : project?.customer_name
                              }
                              onChange={(e) =>
                                setEditProjectDetail({
                                  ...editProjectDetail,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div style={{ width: "100%" }}>
                        <p className="label">Project Title</p>
                        <div className="inputWrapper">
                          <input
                            type="text"
                            name="project_title"
                            id=""
                            placeholder="Write the project title"
                            defaultValue={project?.project_title}
                            readOnly={
                              editProjectDetail?._id === project?._id
                                ? false
                                : true
                            }
                            value={
                              editProjectDetail?._id === project?._id
                                ? editProjectDetail?.project_title
                                : project?.project_title
                            }
                            onChange={(e) =>
                              setEditProjectDetail({
                                ...editProjectDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div style={{ width: "100%" }}>
                        <p className="label">Role</p>
                        <div className="inputWrapper">
                          <input
                            type="text"
                            name="role"
                            id=""
                            placeholder="Write the role"
                            defaultValue={project?.role}
                            readOnly={
                              editProjectDetail?._id === project?._id
                                ? false
                                : true
                            }
                            value={
                              editProjectDetail?._id === project?._id
                                ? editProjectDetail?.role
                                : project?.role
                            }
                            onChange={(e) =>
                              setEditProjectDetail({
                                ...editProjectDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div style={{ width: "100%" }}>
                        <p className="label">About the project</p>
                        <div className="inputWrapper textareaWrapper">
                          <textarea
                            type="text"
                            name="about"
                            id=""
                            rows="6"
                            defaultValue={project?.about}
                            readOnly={
                              editProjectDetail?._id === project?._id
                                ? false
                                : true
                            }
                            value={
                              editProjectDetail?._id === project?._id
                                ? editProjectDetail?.about
                                : project?.about
                            }
                            onChange={(e) =>
                              setEditProjectDetail({
                                ...editProjectDetail,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="newFormButtons">
                        <TwoButtonWrapper
                          firstComponent={"Delete"}
                          backgroundColor={"white"}
                          color={"black"}
                          padding={"5px 20px"}
                          height={"45px"}
                          borderRadius={"10px"}
                          componentFunction={() =>
                            handleDeleteProject(project?._id)
                          }
                        ></TwoButtonWrapper>
                        {editProjectDetail?._id === project?._id ? (
                          <TwoButtonWrapper
                            firstComponent={"Save"}
                            padding={"5px 20px"}
                            height={"45px"}
                            borderRadius={"10px"}
                            componentFunction={() =>
                              handleEditProject(project?._id)
                            }
                          ></TwoButtonWrapper>
                        ) : (
                          <TwoButtonWrapper
                            firstComponent={"Enable Editing"}
                            padding={"5px 20px"}
                            height={"45px"}
                            borderRadius={"10px"}
                            componentFunction={() =>
                              setEditProjectDetail(project)
                            }
                          ></TwoButtonWrapper>
                        )}
                      </div>
                    </div>
                  );
                })}

                <div className="addingProjectComponent">
                  <div>
                    <div style={{ width: "100%" }}>
                      <p className="label">Client/Customer</p>
                      <div className="inputWrapper">
                        <input
                          type="text"
                          name="customer_name"
                          id=""
                          placeholder="Select/Add - client/customer_name"
                          value={newProjectDetail?.customer_name}
                          onChange={(e) =>
                            setNewProjectDetail({
                              ...newProjectDetail,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <p className="label">Project Title</p>
                    <div className="inputWrapper">
                      <input
                        type="text"
                        name="project_title"
                        id=""
                        placeholder="Write the project title"
                        value={newProjectDetail?.project_title}
                        onChange={(e) =>
                          setNewProjectDetail({
                            ...newProjectDetail,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <p className="label">Role</p>
                    <div className="inputWrapper">
                      <input
                        type="text"
                        name="role"
                        id=""
                        placeholder="Write the role"
                        value={newProjectDetail?.role}
                        onChange={(e) =>
                          setNewProjectDetail({
                            ...newProjectDetail,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <p className="label">About the project</p>
                    <div className="inputWrapper textareaWrapper">
                      <textarea
                        type="text"
                        name="about"
                        id=""
                        rows="6"
                        placeholder="Write the project title"
                        value={newProjectDetail?.about}
                        onChange={(e) =>
                          setNewProjectDetail({
                            ...newProjectDetail,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="newFormButtons">
                    <TwoButtonWrapper
                      firstComponent={"Cancel"}
                      backgroundColor={"white"}
                      color={"black"}
                      padding={"5px 20px"}
                      height={"45px"}
                      borderRadius={"10px"}
                      componentFunction={() => setAddingProjectComponent(false)}
                    ></TwoButtonWrapper>
                    <TwoButtonWrapper
                      firstComponent={"Save"}
                      padding={"5px 20px"}
                      height={"45px"}
                      borderRadius={"10px"}
                      componentFunction={handleAddNewProject}
                    ></TwoButtonWrapper>
                  </div>
                </div>
              </>
            )}

            <div className="newFormButtons">
              <TwoButtonWrapper
                firstComponent={"Cancel"}
                backgroundColor={"white"}
                color={"black"}
                padding={"5px 20px"}
                height={"45px"}
                borderRadius={"10px"}
                componentFunction={toggleForm}
              ></TwoButtonWrapper>
              {saveEdited ? (
                <TwoButtonWrapper
                  firstComponent={"Update"}
                  padding={"5px 20px"}
                  height={"45px"}
                  borderRadius={"10px"}
                  componentFunction={() => handleEditExperience(saveEdited)}
                ></TwoButtonWrapper>
              ) : (
                <TwoButtonWrapper
                  firstComponent={"Save"}
                  padding={"5px 20px"}
                  height={"45px"}
                  borderRadius={"10px"}
                  componentFunction={() => handleSaveExp()}
                ></TwoButtonWrapper>
              )}
            </div>
          </div>
        </form>
      )}
      <ToastContainer />
    </main>
  );
};

export default Experience;
